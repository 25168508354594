@import "../../../../assets/colors";

.listContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid $primaryBackground;

  .customerName,
  .productName {
    width: 100%;
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    p {
      color: $primaryColor;
      margin: 0;
      padding-bottom: 6px;
    }

    button {
      padding: 0px 20px 6px 20px;
    }
  }

  .customerName {
    background-color: $primaryBackground;

    p {
      font-size: 20px;
    }

    button {
      @include wisa-button($primaryColor);
      color: $primaryBackground;
      font-size: 14px;
    }
  }

  .productName:not(:nth-child(1)) {
    margin-top: 10px;
  }

  .productName {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba($color: $successRepColor, $alpha: 0.3);

    .textContainer {
      max-width: 80%;

      .editIcon {
        margin-left: 10px;
        cursor: pointer;
      }

      .enableDisableContainer {
        margin-left: 10px;

        input[type="checkbox"] {
          border: 2px solid $primaryBackground;
        }
      }

      p {
        color: $primaryBackground;

        .seeMore {
          border-bottom: 1px solid $primaryBackground;
          cursor: pointer;
          color: $primaryBackground;
          font-size: 14px;
          margin-left: 5px;
          white-space: nowrap;
        }
      }

      p:nth-child(1) {
        font-size: 18px;
      }

      p:nth-child(2) {
        font-size: 14px;
        padding-bottom: 0px;
      }
    }

    button {
      @include wisa-button($primaryBackground);
      color: $primaryColor;
      align-self: flex-start;
      font-size: 14px;
    }
  }
}

.tableTitle {
  font-size: 20px;
  background-color: $successRepColor;
  color: $primaryColor;
  padding: 5px;

  button {
    @include wisa-button(black);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
  }
}

.tableTitleText {
  gap: 20px;
  display: flex;
  align-items: baseline;
}

.noDataFound {
  background-color: $tableEvenRow;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 20px;
}

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  .table {
    width: 100%;
    padding: 10px 30px 30px;
    border-collapse: collapse;

    tbody {
      tr:nth-child(odd) {
        background-color: $tableEvenRow;
      }

      .noDataFound {
        display: table-cell;
        text-align: center;
        font-size: 20px;
      }
    }

    td {
      padding: 5px 15px;
      text-align: left;
      font-size: 14px;
      border-bottom: 1px solid $borderColor;
    }

    th {
      padding: 12px 15px;
      text-align: left;
      font-size: 16px;
      white-space: nowrap;
      border-bottom: 1px solid $borderColor;
    }
    th.action {
      text-align: right;
    }
  }

  .button {
    background-color: white;
    border: none;
    cursor: pointer;
  }
}

.actionCell {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

[role="button"] {
  cursor: pointer;
}
