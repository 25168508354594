@import "../../../assets/colors";
@import "../../../assets/utilities";

.pageContainer {
  .pageHeader {
    margin-bottom: 25px;

    p {
      font-size: 24px;
      margin: 0;
      padding: 0;
    }

    .headerActions {
      * {
        margin-left: 20px;
      }

      button {
        @include wisa-button($successColor);
        color: $primaryColor;
        padding: 0px 20px 6px 20px;
      }

      input {
        width: 300px;
        height: 35px;
        border-radius: 20px;
        border: none;
        padding: 20px;
      }
    }
  }
}

.seqListContainer {
  width: 100%;
  height: 100%;
  background-color: $primaryColor;
  padding: 0px 20px;
  overflow-y: auto;

  .emptyPage {
    height: 50vh;
  }

  .seqItemContainer {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid $borderColor;
    }

    button {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
      height: 30px;
      align-self: center;
    }

    .seqItemActions {
      display: flex;
      align-items: center;

      .tooltip {
        z-index: 100;
        pointer-events: all;

        a {
          color: white;
          font-weight: bold;
        }
      }

      svg {
        margin-right: 20px;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
  .styles.noDataFound {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
