@import "../../../assets/colors";

.configOptions {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;

  .customSelectBox {
    width: 250px;
  }

  .serialNumberInput {
    border: 1px solid $borderColor;
    border-radius: 10px;
    padding: 6px 10px 12px;
    min-width: 250px;
    font-family: "Neue Plak", sans-serif;
    resize: none;
    font-size: 14px;

    &:focus {
      outline: none;

      &::placeholder {
        color: transparent;
      }
    }
  }
}
