$primaryBackground: #2a3042;
$secondaryBackground: #f8f8fb;
$primaryColor: #fff;
$secondaryColor: #7c878e;
$successColor: #007dba;
$borderColor: #dde5ed;
$tableEvenRow: #eeeeee;

$successRepColor: #4ec3e0;
$inProgressColor: #ffb81c;
$pendingColor: #fc4c02;
$failedColor: #ff5555;

@mixin wisa-button($background) {
  background: $background;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-family: "Neue Plak";
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background: darken($background, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($background, 25%);
  }
  &:disabled {
    background: $borderColor;
    cursor: not-allowed;
  }
}

@mixin checkbox($color) {
  margin-right: 1rem;
  padding-left: 1.75rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:focus ~ .indicator {
      border: 2px solid lighten($borderColor, 50%);
    }

    &:focus:checked ~ .indicator {
      border: none;
    }

    &:checked ~ .indicator {
      color: $primaryColor;
      background: $color
        url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+)
        50% 40% no-repeat;
      border: 2px solid $color;
    }
  }

  input[type="checkbox"]:focus {
    outline: 0;
  }

  .indicator {
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 10px;
    width: 1rem;
    height: 1rem;
    background-color: lighten($primaryBackground, 65%);
    border: 2px solid lighten($primaryBackground, 65%);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkboxLabel {
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  background: rgba($color, $opacity);
}
