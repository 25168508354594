@import "../../../assets/colors";

.dialogContainer {
  width: 300px;

  p.title {
    color: $primaryBackground !important;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  form {
    margin: 10px 0;

    .fieldContainer {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 10px;

      p {
        color: $primaryBackground !important;
        padding: 0;
        margin: 0 0 10px 0;
      }

      .error {
        color: $failedColor !important;
        font-size: 12px;
        margin-bottom: 0;
      }

      select {
        width: 70%;
      }

      input {
        width: 70%;
        height: 40px;
        padding: 0px 16px 6px 16px;
        font-family: "Neue Plak";
        border: 1px solid $borderColor;
        resize: none;

        &:focus {
          outline: none;

          &::placeholder {
            color: transparent;
          }
        }
      }

      p {
        font-size: 14px;
      }
    }

    .buttonsContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 20px;

      button {
        background-color: $successColor !important;
      }
    }
  }
}
