@import "../../../assets/colors";

.dataContainer {
  width: 100%;
  background-color: $primaryColor;
  padding: 20px;
}

.tabsContainer {
  background-color: $primaryColor;
  color: $primaryBackground;
  display: grid;
  list-style: none;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px;
  place-content: center;
  place-items: stretch;

  a {
    border: 1px solid $successColor;
    border-right: none;
    color: $primaryBackground;
    line-height: 60px;
    text-align: center;
    text-decoration: none;

    &:last-child {
      border-right: 1px solid $successColor;
    }

    &.active {
      background: $successColor;
      color: $primaryColor;
    }
  }
}
