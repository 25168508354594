@import "../../../assets/colors";
@import "../../../assets/utilities";

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  .table {
    width: 100%;
    padding: 10px 30px 30px;
    border-collapse: collapse;

    tbody {
      tr:nth-child(even) {
        background-color: #eeeeee;
      }

      tr {
        border-bottom: 1px solid $borderColor;

        .customerName {
          display: inline-block;
          max-width: 400px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }

    td {
      padding: 5px 15px;
      text-align: left;
      font-size: 14px;

      span.manufacturerDot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;
        margin: 0 auto;

        &.manufacturer {
          background-color: $successColor;
        }
        &.user {
          background-color: $pendingColor;
        }
      }
    }

    th {
      padding: 12px 15px;
      text-align: left;
      font-size: 16px;
      white-space: nowrap;
      border-bottom: 1px solid $borderColor;

      &.center {
        text-align: center;
      }

      &.vendorName {
        width: 400px;
      }
    }
    th.action {
      text-align: right;
    }
  }

  .button {
    background-color: white;
    border: none;
    cursor: pointer;
  }

  .paginationContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      &.active {
        color: $primaryBackground;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}

.pageContainer {
  .pageHeader {
    margin-bottom: 25px;

    p {
      font-size: 24px;
      margin: 0;
      padding: 0;
    }

    .headerActions {
      * {
        margin-left: 20px;
      }

      button {
        @include wisa-button($successColor);
        color: $primaryColor;
        padding: 0px 20px 6px 20px;
      }

      input {
        width: 300px;
        height: 35px;
        border-radius: 20px;
        border: none;
        padding: 20px;
      }
    }
  }
  .switchinfo {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .emptyPage {
    height: 50vh;
  }
  .styles.noDataFound {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.approveButton {
  @include wisa-button($successColor);
  color: $primaryColor;
  padding: 0px 20px 6px 20px;
  width: 120px;
}

.assignToContainer {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  p {
    text-align: center;
  }
}
