@import "../../../assets/colors";

.footer {
  width: 100%;
  background-color: $primaryColor;
  color: $secondaryColor;
  padding: 25px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  .aboutApp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
