@import "../../../../assets/colors";
@import "../../../../assets/utilities";

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;

  .table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 5px 15px;
      text-align: center;
      font-size: 14px;
      border: 1px solid $borderColor;

      &:nth-child(1) {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      p {
        color: $primaryBackground;
        margin: 0;
        padding: 0;
        font-weight: bold;
      }
    }

    th {
      padding: 12px 15px;
      border: 1px solid $borderColor;
      text-align: center;
      font-size: 16px;
      color: $successColor;
      white-space: nowrap;

      span.info {
        margin-left: 5px;
        cursor: pointer;
      }

      &.tooltipHeader {
        cursor: pointer;
      }

      &:nth-child(1) {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  .button {
    background-color: white;
    border: none;
    cursor: pointer;
  }

  .paginationContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      &.active {
        color: $primaryBackground;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}

.addMcuButton {
  display: flex;
  justify-content: center;

  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
    margin-top: 20px;
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid $primaryBackground;

  .configurationWrapper {
    width: 100%;
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    min-height: 80px;

    .header {
      flex-direction: column;
      display: flex;

      .titleContainer + p {
        font-size: 16px;
        padding-bottom: 5px;
      }

      .titleContainer {
        display: flex;
        align-items: center;
        flex-direction: row;

        .editIcon {
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }

    p {
      color: $primaryColor;
      margin: 0;
      padding-bottom: 6px;
    }

    button {
      padding: 0px 20px 6px 20px;
    }

    .enableDisableContainer {
      input[type="checkbox"] {
        border: 2px solid #fff;
      }
      display: flex;
      align-items: center;
    }
  }

  .configurationWrapper {
    background-color: $primaryBackground;

    p {
      font-size: 20px;
    }

    button {
      @include wisa-button($primaryColor);
      color: $primaryBackground;
      font-size: 14px;
    }
  }
}

.actionCell {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .deleteContainer {
    cursor: pointer;
  }
}

.addButton {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
    margin-bottom: 20px;
  }
}
