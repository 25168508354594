@import "../../../assets/colors";

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 550px;
    max-height: 300px;
    background-color: $primaryColor;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .statusBoxContainer {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .statusBox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 140px;
        gap: 20px;
        padding: 20px;
        border: 2px solid $primaryBackground;
      }
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }
  }
}
