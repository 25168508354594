@import "../../../assets/colors";

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 500px;
    min-width: 375px;
    background-color: $primaryColor;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .inputContainer {
      width: calc(50% - 10px);
      flex-direction: column;
      align-items: flex-start;

      &.selection {
        width: 210px;
      }

      &:only-child {
        width: calc(100% - 10px);
      }

      :global(.react-tagsinput) {
        border: none;
        padding: 0;
      }

      .minMaxContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex-direction: row;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 40px;
      padding: 0px 16px 6px 16px;
      font-family: "Neue Plak";
      border: 1px solid $borderColor;
      resize: none;

      &:focus {
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }
    }

    .inputRow {
      display: flex;
      gap: 20px;
      width: 100%;

      label {
        margin: 0;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 40px;
      padding: 0px 16px 6px 16px;
      font-family: "Neue Plak";
      border: 1px solid $borderColor;
      resize: none;

      &:focus {
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
      -webkit-text-fill-color: #000;
      font-family: "Neue Plak";
    }

    textarea {
      padding-top: 6px;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &Content {
      font-size: 16px;
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }

    .error {
      color: $failedColor;
      font-size: small;
    }
  }
}

.wisaFirmwareUploadForm {
  &Fieldset {
    border: none;
    display: grid;
    grid-gap: 25px 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 40px 40px 40px 80px 40px;
  }

  &GridItem {
    grid-column: span 2;

    &:last-child {
      grid-column: 2 / span 2;
    }
  }

  &Field {
    background-color: $primaryColor;
    border: none;
    border-bottom: 2px solid $borderColor;
    text-align: left;
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    width: 324px;
  }

  &IconText {
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    margin-top: 0;
  }

  &IconLabel {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    grid-column: span 2;
  }

  &UploadIcon {
    fill: $successColor;
    height: 40px;
    margin-left: -10px;
  }

  &CheckboxWrapper {
    align-items: flex-start;
    display: flex;
    grid-column: 1 / span 2;
    margin-top: 22px;
  }

  &FileName {
    color: $successColor;
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    margin: 0;
  }

  label {
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    height: auto;
    margin-bottom: 0;
    margin-top: 0;

    span {
      top: 3px;
    }
  }
}
