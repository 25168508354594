@import "../../../assets/colors";

form {
  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
  }

  .addAnother {
    width: fit-content;
    border-bottom: 1px solid $primaryBackground;
    cursor: pointer;
  }
}

.addModelContainer {
  width: 100%;
  display: flex;
  border: 1px solid $primaryBackground;
  margin-top: 20px;
  padding: 20px;
  position: relative;
  min-height: 135px;

  .trashIcon {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.addProductContainer,
.addModelContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .inputItem {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 10px;

    .error {
      color: $failedColor;
      font-size: small;
    }

    input,
    textarea,
    select {
      border: 1px solid $borderColor;
      border-radius: 10px;
      padding: 6px 10px 12px;
      margin-top: 10px;
      min-width: 250px;
      font-family: "Neue Plak", sans-serif;
      resize: none;
      font-size: 14px;

      &:focus {
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }
    }

    select {
      padding: 6px 10px 11px;
      background-size: 5px 5px, 5px 5px, 2.7em 2.7em;
    }
  }

  .configContainer {
    width: 100%;
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .radioContainer {
    margin-right: 20px;

    div {
      label {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 1440px) {
    .inputItem {
      width: calc(33.33% - 40px);
    }

    .radioContainer {
      width: calc(33.33% - 40px);
    }
  }

  @media (max-width: 1200px) {
    .inputItem {
      width: calc(50% - 40px);
    }

    .radioContainer {
      width: calc(50% - 40px);
    }
  }

  @media (max-width: 992px) {
    .inputItem {
      width: 100%;
    }

    .radioContainer {
      width: 100%;
    }
  }
}

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 900px;
    background-color: $primaryColor;
    padding: 20px;
    gap: 20px;
    position: relative;
    z-index: 9;

    .btnContainer {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      width: 100%;
      margin-top: 20px;
    }

    .addModelContainer {
      margin-top: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &Content {
      font-size: 16px;
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }

    .error {
      color: $failedColor;
      font-size: small;
    }
  }
}

.configurationContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $borderColor;
  margin-top: 20px;

  label {
    width: fit-content;
  }

  .tooltipContainer {
    display: flex;
    align-items: center;
    gap: 5px;

    label {
      margin: 0;
    }

    span {
      cursor: pointer;
    }
  }

  > div {
    width: 50%;
    height: 100%;

    .header {
      text-align: center;
      padding: 10px;
      background-color: $borderColor;
    }

    .subHeader {
      text-align: center;
      text-decoration: underline;
      font-weight: bold;
      margin-top: 0;
    }

    &:not(:first-child) {
      border-left: 1px solid $borderColor;
    }

    .configs {
      display: flex;
      flex-wrap: wrap;

      > div {
        width: 50%;
        flex-basis: 50%;
        padding: 0 20px 20px;

        @media (max-width: 992px) {
          width: 100%;
          flex-basis: 100%;
        }
      }

      .regionContainer {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $borderColor;
        padding: 0 20px;

        label {
          width: 44%;
          margin-top: 0;
        }

        .otherRegionCheckboxContainer {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 5px;

          label {
            width: fit-content;
          }

          .otherRegionInputContainer {
            display: flex;
            flex-direction: column;

            .error {
              font-size: 12px;
              color: $failedColor;
            }
          }
        }

        input {
          border: 1px solid #dde5ed;
          border-radius: 10px;
          padding: 0 0 5px 10px;
          font-family: "Neue Plak", sans-serif;
          resize: none;
          font-size: 14px;

          &:focus {
            outline: none;
          }
        }
      }

      .borderBox {
        border: 1px solid $borderColor;
        padding: 0 20px;
      }

      .energyStarContainer {
        width: 100%;
        display: flex;
        align-items: baseline;
        flex-direction: column;

        label {
          width: auto;
        }

        .timeoutInputContainer {
          display: flex;
          flex-direction: column;

          .timeoutInput {
            display: flex;
            flex-direction: row;
            position: relative;

            & > span {
              margin-left: 5px;
            }
          }
          .error {
            font-size: 12px;
            color: $failedColor;
          }
        }

        input {
          border: 1px solid #dde5ed;
          border-radius: 10px;
          padding: 0 0 5px 10px;
          font-family: "Neue Plak", sans-serif;
          resize: none;
          font-size: 14px;

          &:focus {
            outline: none;
          }
        }
      }
    }

    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}
