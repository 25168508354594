@import "../../../assets/colors";

.statsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .stats {
    width: 24%;
    height: 140px;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin: 0;

      &.statTitle {
        font-size: 16px;
      }

      &.count {
        font-size: 26px;
        font-weight: bold;
        margin: 6px 0;
      }
    }

    .miniStats {
      width: 100%;
      display: flex;
      justify-content: space-between;
      
      p {
        font-size: 14px;
      }
    }
  }
}

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  .table {
    width: 100%;
    padding: 10px 30px 30px;
    border-collapse: collapse;

    tbody {

      tr.bodyRow {
        height: 55px;
      }

      tr:nth-child(even) {
        background-color: $tableEvenRow;
      }

      tr {
        border-bottom: 1px solid $borderColor;

        .customerName {
          display: inline-block;
          max-width: 400px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }

    td {
      padding: 5px 15px;
      text-align: left;
      font-size: 14px;

      &.center {
        text-align: center;
      }

      span.manufacturerDot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;
        margin: 0 auto;

        &.manufacturer {
          background-color: $successColor;
        }
        &.user {
          background-color: $pendingColor;
        }
      }
    }

    th {
      padding: 12px 15px;
      text-align: left;
      font-size: 16px;
      white-space: nowrap;
      border-bottom: 1px solid $borderColor;

      &.center {
        text-align: center;
      }

      &.vendorName {
        width: 400px;
      }
    }
    th.action {
      text-align: right;
    }
  }

  .button {
    background-color: white;
    border: none;
    cursor: pointer;
  }
}

.pageHeader {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }

  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
  }
}
