@import "../../../assets/colors.scss";

.modal {
  align-content: center;
  align-items: center;
  background-color: rgba($primaryBackground, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.modalClose {
  color: $primaryColor;
  line-height: 1;
  position: absolute;
  right: 4px;
  top: 2px;
}

.modalMain {
  background-color: $primaryColor;
  padding: 20px;
}
