@import "../../../assets/colors";

.dialogContainer {
  width: 300px;
  padding: 10px;

  input {
    width: 100%;
    height: 40px;
    padding: 0px 16px 6px 16px;
    font-family: "Neue Plak";
    border: 1px solid $borderColor;
    resize: none;

    &:focus {
      outline: none;

      &::placeholder {
        color: transparent;
      }
    }
  }

  select {
    width: 100%;
    margin: 10px 0;
  }

  .inputContainer {
    margin-bottom: 10px;
    .error {
      color: $failedColor;
      font-size: small;
      margin: 0;
      padding: 0;
    }
  }
}
