@import "../../../assets/colors";

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 500px;
    background-color: $primaryColor;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .csvInstructions {
      color: $primaryBackground;
      font-size: 14px;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-top: 4px;
        }
      }

      .csvInstructionsTitle {
        font-weight: bold;
      }
    }

    .fileUpload {
      width: 100%;
      height: 150px;
      border: 1px dashed $borderColor;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      input[type="file"] {
        display: none;
      }

      .selectedFile {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &.dragging,
      &.selected {
        border: 1px dashed $primaryBackground;
        background-color: rgb($borderColor, 0.5);
      }

      .fileUploadLink {
        color: $successColor;
        text-decoration: underline;
      }
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }
  }
}
