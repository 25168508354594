@import "../../../assets/colors";

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 500px;
    background-color: $primaryColor;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .inputContainer {
      width: 70%;
      flex-direction: column;
      margin-right: 10px;
    }

    select {
      font-size: 14px;
    }

    input {
      background-color: $primaryColor;
      border: none;
      width: 100%;
      border-bottom: 2px solid $borderColor;
      text-align: center;
      padding: 10px;
      font-style: normal;
      font-family: "Neue Plak";
      font-weight: normal;
      font-size: 13px;

      &:focus {
        outline: none;
      }
    }

    input,
    textarea {
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    &Content {
      font-size: 16px;
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }

    .error {
      color: $failedColor;
      font-size: small;
    }
  }
}
