@import "../../../assets/colors";

.logsContainer {
  display: flex;
  flex-basis: calc(50% - 12px);
  min-height: calc(100vh - 255px);
  align-items: baseline;
  background-color: $primaryColor;
  flex-direction: column;
  overflow-y: visible;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 10px;
  }

  p {
    width: 100%;
    text-align: center;
  }

  .responseContainer {
    width: 100%;
    padding: 0 22px;

    .response {
      display: flex;
      justify-content: space-between;
      flex-basis: 100%;
      border-bottom: 1px solid $secondaryColor;
      margin-bottom: 20px;

      p {
        margin: 0;
        padding: 0 0 10px;
        font-size: 16px;
        word-break: break-all;
      }

      p:nth-child(1) {
        width: 50%;
        text-align: left;
        padding-left: 10px;
        color: $primaryBackground;
        padding-right: 10px;
      }

      p:nth-child(2) {
        width: 50%;
        text-align: right;
        padding-right: 10px;
        color: $secondaryColor;
        padding-left: 10px;
      }
    }
  }
}
