@for $i from 10 to 110 {
  .w-#{$i} {
    width: unquote($i + "%");
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-around {
  justify-content: space-around;
}

.align-item-center {
  align-items: center;
}

.align-item-baseline {
  align-items: baseline;
}

.h-100 {
  height: 100vh;
}

.d-none {
  display: none;
}
