@import "../../../assets/colors";

.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .loginBox {
    width: 500px;
    min-height: 370px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    .headerSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      background-color: $primaryBackground;
      padding: 20px;

      img {
        width: 120px;
      }

      div {
        p,
        h2 {
          margin: 0;
          padding: 0;
          color: $primaryColor;
          text-align: right;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .inputsSection {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 30px;

      .inputItem {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        width: 80%;

        .error {
          color: $failedColor;
          font-size: small;
        }

        input {
          border: 1px solid $borderColor;
          border-radius: 10px;
          padding: 6px 10px 12px;
          margin-top: 10px;
          min-width: 250px;
          font-family: "Neue Plak", sans-serif;
          resize: none;
          font-size: 14px;

          &:focus {
            outline: none;

            &::placeholder {
              color: transparent;
            }
          }
        }

        select {
          padding: 6px 10px 11px;
          background-size: 5px 5px, 5px 5px, 2.7em 2.7em;
        }
      }

      button {
        @include wisa-button($successColor);
        color: $primaryColor;
        padding: 0px 20px 6px 20px;
        margin: 20px;
      }
    }
  }
}
