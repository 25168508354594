@import "../../../assets/colors";

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 500px;
    background-color: $primaryColor;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .passwordCheckBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 5px;

      label {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .inputContainer {
      width: calc(50% - 10px);
      flex-direction: column;
      align-items: flex-start;

      &:only-child {
        width: 100%;
      }

      :global(.react-tagsinput) {
        border: none;
        padding: 0;
      }

      .minMaxContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex-direction: row;
      }

      .passwordContainer {
        position: relative;
        width: 100%;

        input {
          padding-right: 35px;
        }

        button {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 10px;
          width: 18px;
          height: 24px;
          cursor: pointer;
          background-color: transparent;

          svg {
            fill: rgba($primaryBackground, 0.5);
            width: 19px;

            line {
              stroke: rgba($primaryBackground, 0.5);
            }
          }
        }
      }
    }

    .inputRow {
      display: flex;
      gap: 20px;
      width: 100%;

      label {
        margin: 0;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 40px;
      padding: 0px 16px 6px 16px;
      font-family: "Neue Plak";
      border: 1px solid $borderColor;
      resize: none;

      &:focus {
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
      -webkit-text-fill-color: #000;
      font-family: "Neue Plak";
    }

    textarea {
      padding-top: 6px;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &Content {
      font-size: 16px;
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }

    .error {
      color: $failedColor;
      font-size: small;
    }

    .fullWidthSelect {
      select {
        width: 98%;
        margin: 0 auto;
      }
    }
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 98%;
  margin: 0 auto;

  .tagsItem {
    max-width: fit-content;
    background-color: $successColor;
    color: $primaryColor;
    font-family: "Neue Plak", sans-serif;
    padding: 0px 10px 6px 10px;
    border: none;
    display: flex;
    
    span {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}
