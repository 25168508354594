@import "../../../assets/colors";

.pageContainer {
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    p {
      font-size: 22px;
      margin: 0;
      padding: 0;

      span {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}

.moduleContainer {
  align-items: flex-start;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.informationContainer {
  flex-basis: calc(50% - 12px);
  flex-wrap: wrap;
  height: auto;
  align-items: baseline;

  img {
    width: 100%;
    margin-top: 15px;
    height: 220px;
    object-fit: contain;
    object-position: center;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.commandsContainer {
  flex-basis: 100%;
  flex-direction: column;
  background-color: $primaryColor;
  padding: 25px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .command {
    padding: 10px;
    border-bottom: 1px solid $borderColor;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(2) {
      margin-top: 10px;
    }

    &:last-child {
      border-bottom: none;
    }

    label {
      display: flex;
      gap: 6px;
      font-size: 14px;
    }

    span {
      font-weight: bold;
      font-size: 12px;
      padding: 0px 5px 5px 5px;
      border-radius: 5px;
      white-space: nowrap;
    }
  }
}

.infoboxContainer {
  padding: 25px;
  background-color: $primaryColor;
  flex-direction: column;

  @media screen and (max-width: 1200px) {
    width: 100% !important;
  }

  label {
    border: 1px solid $borderColor;
    padding: 10px;
    border-radius: 6px;
    margin-top: 10px;
    color: $secondaryColor;
    min-height: 45px;
  }

  select {
    margin-top: 10px;
  }
}

.scannedMacInfoBox {
  width: 100%;
}

.deviceInfo {
  margin-bottom: 10px;

  .infoboxContainer {
    width: 260px;
  }

  .scannedMacInfoBox {
    width: calc(100% - 270px);
  }
}

.infoboxContainer {
  width: 100%;

  @media screen and (min-width: 1200px) {
    width: 100%;
  }

  &Transparent {
    background-color: transparent;
  }
}

.customSelectBox {
  width: auto;
}

.deviceConfiguration {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.multiMac {
  flex-wrap: wrap;

  .infoboxContainer:nth-child(2) {
    width: 100% !important;

    span {
      margin-bottom: 10px;
    }

    .macAddressContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;

      button {
        @include wisa-button($successColor);
        color: $primaryColor;
        padding: 0px 20px 6px 20px;
        height: 45px;
      }

      label {
        width: 49%;
        margin-top: 0;
      }
    }
  }
}

.firmwareProgrammingDeviceInfo {
  flex-direction: column;
}

.firmwareProgrammingMacScanInputWrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1281px) {
    flex-direction: row;
  }

  .infoboxContainer {
    width: 100%;

    select {
      width: 30%;
    }

    .macAddressContainer {
      display: flex;
      gap: 20px;

      label {
        display: block;
        width: 50%;
      }
    }
  }
}

.statusContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin: 0;
    padding: 0;
    margin-top: -5px;
    margin-left: 5px;
  }

  .statusDot {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 6px;
    display: flex;

    &.Disconnected {
      box-shadow: 0 0 0 3px transparentize($failedColor, 0.8);
      background-color: $failedColor;
    }
    &.Connected {
      box-shadow: 0 0 0 3px transparentize($successRepColor, 0.8);
      background-color: $successRepColor;
    }
  }
}

.success {
  color: $successColor;
  @include background-opacity($successColor, 0.1);
}
.inProgress {
  color: $inProgressColor;
  @include background-opacity($inProgressColor, 0.1);
}
.pending {
  color: $pendingColor;
  @include background-opacity($pendingColor, 0.1);
}
.failed {
  color: $failedColor;
  font-size: 14px;
  @include background-opacity($failedColor, 0.1);
}
.skipped {
  color: $secondaryColor;
  @include background-opacity($secondaryColor, 0.1);
}

.howItWorksInfo {
  width: 600px;
  padding: 20px;

  .aboutStatus {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $borderColor;

    span {
      font-weight: bold;
      font-size: 12px;
      padding: 0px 5px 5px 5px;
      border-radius: 5px;
      white-space: nowrap;

      &.icon {
        padding: 0;
      }
    }
  }
}

.assignMacContainer {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
  }
}

.startProgrammingButton {
  @include wisa-button($successColor);
  color: $primaryColor;
  padding: 0px 20px 6px 20px;
  margin-bottom: 16px;
}

.remoteMacCheckbox {
  margin-left: 25px;
}
