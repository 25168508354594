@import "../../../assets/colors";
.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 35vw;
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .dialogBtnContainer {
    margin-top: 30px;
  }

  .dialogBtn {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
  }

  .error {
    color: $failedColor;
    font-size: small;
  }

  .moduleCheckbox {
    width: 50%;
  }
}
