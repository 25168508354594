@import "../../../assets/colors";

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;

  .table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 5px 15px;
      text-align: center;
      font-size: 14px;
      border: 1px solid $borderColor;

      &:nth-child(1) {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      p {
        color: $primaryBackground;
        margin: 0;
        padding: 0;
        font-weight: bold;
      }

      input {
        background-color: transparent;
        border: none;
        width: 50%;
        border-bottom: 2px solid $borderColor;
        text-align: center;

        &:focus {
          outline: none;

          &::placeholder {
            color: transparent;
          }
        }

        &.invalid {
          border-bottom: 2px solid $pendingColor;
        }
      }
    }

    th {
      padding: 12px 15px;
      border: 1px solid $borderColor;
      text-align: center;
      font-size: 16px;
      color: $successColor;
      white-space: nowrap;

      &.tooltipHeader {
        cursor: pointer;
      }

      &:nth-child(1) {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.tableTitle {
  font-size: 20px;
  background-color: $primaryBackground;
  color: $primaryColor;
  text-align: center;
  padding: 5px;
}
