@import "../../../assets/colors";

.pageContainer {
  width: 100%;
  height: 100%;

  .titleContainer {
    height: 35px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }

    svg {
      cursor: pointer;
    }
  }
  .editorContainer {
    height: calc(100% - 85px);
    gap: 30px;
  }
}

.commandList {
  width: 70%;
  height: 100%;
  background-color: $primaryColor;
  list-style: none;

  .commandsContainer {
    height: calc(100% - 70px);
    overflow-y: auto;
    ul {
      padding: 0;
      margin: 0;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        display: inline-block;
        margin: 15px;
        font-size: 20px;
        width: calc(33.33% - 30px);

        p {
          margin: 0;
          padding: 0;
          color: $secondaryColor;
          font-size: 14px;
        }

        label {
          padding-top: 0;
          padding-bottom: 0;
          margin: 0;
        }

        @media screen and (max-width: 1919px) {
          width: calc(50% - 30px);
        }

        @media screen and (max-width: 1299px) {
          width: 100%;
        }

        input[type="checkbox"] {
          margin-right: 10px;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .commandHeader {
    background: $borderColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    label {
      font-size: 24px;
    }
    input {
      background: $primaryColor;
      width: 300px;
      height: 35px;
      border-radius: 20px;
      border: none;
      padding: 20px;
    }
    .searchCommand {
      display: flex;
      align-items: center;

      button {
        @include wisa-button($successColor);
        color: $primaryColor;
        padding: 0px 20px 6px 20px;
      }
    }
  }
}

.seqContainer {
  width: 30%;
  height: 100%;
  background-color: $primaryColor;

  .seqHeader {
    background: $borderColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    label {
      font-size: 24px;
    }

    .searchCommand {
      display: flex;
      align-items: center;

      button {
        height: 35px;
        width: 35px;
        border: 0;
        background: $successColor;
        border-radius: 100%;
        padding: 0;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .seqItem {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    p {
      width: calc(100% - 90px);
      word-break: break-all;
    }

    .seqActions {
      display: flex;
      align-items: center;
      width: 90px;

      svg:nth-child(1) {
        margin-left: 20px;
        cursor: pointer;
      }

      svg:nth-child(2) {
        margin-left: 20px;
        cursor: grab;
      }
    }
  }
}

.sortableContainer {
  overflow-y: auto;
  height: calc(100% - 70px);
}

.checkbox {
  @include checkbox($successColor);
}

.modelContainer {
  width: 300px;
  padding: 20px;

  .inputContainer {
    width: 100%;

    input {
      width: 100%;
      height: 40px;
      padding: 0px 16px 6px 16px;
      font-family: "Neue Plak";

      &:focus {
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }
    }

    .error {
      font-size: 12px;
      color: $failedColor;
    }
  }
}

.inputValuesModel {
  width: 600px;
  height: 600px;

  p.heading {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .commandListContainer {
    height: 500px;
    overflow: auto;
    background: #dddddd75;
    padding: 0 20px;

    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dddddd75;
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: content-box;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
      }
    }

    .commandItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $borderColor;

      p {
        width: 50%;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:nth-child(2) {
          text-align: center;
        }
      }

      .customInput {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .error {
          color: $failedColor;
          font-size: 12px;
        }

        .dynamicInput {
          width: 210px;
          height: 35px;
          border: 1px solid $borderColor;
          padding: 0 10px;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .actionButtons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
}

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  overflow: auto;
  height: 100%;

  .table {
    width: 100%;
    padding: 10px 30px 30px;
    border-collapse: collapse;
    position: relative;

    thead th {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 999;
    }

    tbody {
      tr:nth-child(even) {
        background-color: $tableEvenRow;
      }

      tr {
        border-bottom: 1px solid $borderColor;
      }
    }

    td {
      padding: 5px 15px;
      text-align: left;
      font-size: 14px;

      &.seperatorTd {
        line-height: 50px;
      }
    }

    th {
      padding: 12px 15px;
      text-align: left;
      font-size: 16px;
      white-space: nowrap;
      border-bottom: 1px solid $borderColor;
    }
  }

  table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
  }
  th {
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
  }
}
