@import "../../../assets/colors";

aside {
  width: 250px;
  height: 100vh;
  background-color: $primaryBackground;

  .logoSection {
    padding: 20px 0;

    a {
      display: flex;
      justify-content: center;
    }
  }

  ul {
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: calc(100% - 80px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      background-color: $primaryBackground;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondaryColor;
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: content-box;
    }

    &::before {
      content: "MENU";
      color: $secondaryColor;
      margin-left: 30px;
      font-size: 12px;
    }

    li {
      padding: 20px 10px 20px 30px;

      a {
        color: $primaryColor;
        text-decoration: none;
      }
    }
  }
}
