@import "../../../assets/colors";

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .dialog {
    width: 500px;
    height: 200px;
    background-color: $primaryColor;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
      padding: 0;
    }

    .dialogHeader {
      font-size: 20px;
      font-weight: 700;
    }

    &Content {
      font-size: 16px;
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }
  }
}
