@import "../../../assets/colors";

.dialogContainer {
  background-color: rgba($primaryBackground, 0.5);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 350px;
    height: 150px;
    background-color: $primaryColor;
    padding: 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    .inputContainer {
      width: 100%;

      input {
        width: 100%;
        height: 40px;
        padding: 0px 16px 6px 16px;
        font-family: "Neue Plak";

        &:focus {
          outline: none;

          &::placeholder {
            color: transparent;
          }
        }
      }

      .error {
        font-size: 12px;
        color: $failedColor;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    &Content {
      font-size: 16px;
    }

    .dialogBtn {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
    }
  }
}
