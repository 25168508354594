@import "../../../assets/colors";

.tabsContainer {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: flex-start;
  background-color: $primaryColor;

  .tabsList {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 65%;
    overflow-y: auto;
    display: flex;
    border: 1px solid $successColor;
    height: 60px;

    &::-webkit-scrollbar {
      background-color: $primaryBackground;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondaryColor;
      border: 2px solid transparent;
      border-radius: 8px;
      background-clip: content-box;
    }

    li {
      border-right: 1px solid $successColor;
      padding: 12px 20px 18px 20px;
      cursor: pointer;
      display: inline-flex;
      white-space: nowrap;
      align-items: center;

      &:last-child {
        border-right: none;
      }

      &.active {
        background: $successColor;
        color: $primaryColor;
      }
    }
  }

  .optionsContainer {
    border: 1px solid $successColor;
    width: 35%;
    height: 60px;
    border-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  input {
    width: 300px;
    height: 35px;
    border-radius: 20px;
    border: none;
    padding: 6px 10px 12px;
    background-color: $borderColor;
    font-family: "Neue Plak", sans-serif;
  }
}

.dataContainer {
  width: 100%;
  background-color: $primaryColor;
  padding: 20px;

  .empty {
    text-align: center;
    color: $secondaryColor;
    height: 100%;
    font-size: 20px;
  }

  .productItem {
    border: 3px solid $borderColor;
    padding: 20px;
    margin-bottom: 20px;

    p {
      margin: 0;
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: bold;
    }

    .modelItem {
      border: 2px solid $borderColor;
      padding: 6px 10px 12px;
      margin-bottom: 10px;
      cursor: pointer;

      .modelTitle,
      .modelDesc {
        margin: 0;
        padding: 0;
      }

      .modelTitle {
        font-size: 18px;
        font-weight: bold;
      }

      .modelDesc {
        font-size: 14px;
        color: $secondaryColor;
        margin-top: 8px;
      }

      &.active {
        border: none;
        background-color: $successColor;
        color: $primaryColor;

        .modelDesc {
          color: $borderColor;
        }
      }
    }
  }
}
