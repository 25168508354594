@import "../../../assets/colors";

.header {
  width: 100%;
  background-color: $primaryColor;
  padding: 12px 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  min-height: 80px;

  .col {
    display: flex;
    flex: 1;
    justify-content: center;

    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
  .context {
    position: relative;
    min-width: 200px;
    max-width: 400px;

    span {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .trash {
      position: absolute;
      top: 0;
      right: 0;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
    align-content: flex-end;
  }

  .profileDropdown {
    position: relative;
    cursor: pointer;

    .dropdown {
      float: left;
      overflow: hidden;
      display: flex;
      align-items: center;
      position: relative;

      .arrow {
        transform: rotate(-90deg);
        width: 18px;
        margin: 5px 5px 0;
        transition: all 0.2s linear;
      }
    }

    .dropdownContent {
      display: none;
      position: absolute;
      background-color: transparent;
      width: 145px;
      padding: 12px 0;
      z-index: 1;
      top: 20px;
      right: 0;

      .logoutBtn {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;
        text-align: center;
        padding: 8px 0;
      }

      a:hover {
        background-color: #ddd;
      }
    }

    &:hover {
      .dropdownContent {
        display: block;
      }

      .arrow {
        transform: rotate(90deg);
      }
    }
  }
}
