@import "../../../assets/colors";
@import "../../../assets/utilities";

.pageContainer {
  .pageHeader {
    margin-bottom: 25px;

    p {
      font-size: 24px;
      margin: 0;
      padding: 0;
    }

    .headerActions {
      * {
        margin-left: 20px;
      }

      button {
        @include wisa-button($successColor);
        color: $primaryColor;
        padding: 0px 20px 6px 20px;
      }
    }
  }
  .emptyPage {
    height: 50vh;
  }
  .styles.noDataFound {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .processListContainer {
    .processItem {
      width: 100%;
      padding: 20px;
      background-color: $inProgressColor;
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button,
      a {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $primaryBackground;
        cursor: pointer;
        padding: 0;
        margin: 0 10px;
        font-size: 14px;
        text-decoration: none;
      }

      &.failedProcesses {
        @include background-opacity($failedColor, 0.8);
        color: $primaryColor;

        button,
        a {
          color: $primaryColor;
          border-bottom: 1px solid $primaryColor;
        }
      }
    }
  }
}

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  .table {
    width: 100%;
    padding: 10px 30px 30px;
    border-collapse: collapse;

    tbody {
      tr:nth-child(even) {
        background-color: $tableEvenRow;
      }

      tr {
        border-bottom: 1px solid $borderColor;
      }
    }

    td {
      padding: 5px 15px;
      text-align: left;
      font-size: 14px;

      &.center {
        text-align: center;
      }

      .customLoader {
        font-size: 30px;
      }

      &.noDataFound {
        text-align: center;
        padding: 20px 0;
      }
    }

    th {
      padding: 12px 15px;
      text-align: left;
      font-size: 16px;
      white-space: nowrap;
      border-bottom: 1px solid $borderColor;

      &.center {
        text-align: center;
      }
    }
  }

  .paginationContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      &.active {
        color: $primaryBackground;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  .button {
    background-color: white;
    border: none;
    cursor: pointer;
  }
}
