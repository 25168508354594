.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.wrapper {
  display: flex;

  .contentWrapper {
    width: calc(100% - 250px);

    .pageWrapper {
      padding: 25px;
      overflow: auto;
      height: calc(100vh - 150px);

      &.contextSelected {
        height: calc(100vh - 200px);
      }
    }
  }
}

.backBtn {
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    padding-bottom: 4px;
    margin-left: 10px;
  }
}
