@import "../../../assets/colors";

.switch {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.toggle {
  height: 22px;
  width: 42px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  margin: 0;
  border: 2px solid $borderColor;
  transition: all 0.2s ease;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
    transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
    background: $successColor;
  }

  &:checked {
    background: $successColor;

    &:after {
      transform: translatex(20px);
      background: white;
    }
  }
}
