@import "../../../assets/colors";

.container1 {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 20px;

  > div:nth-child(1) {
    width: 60%;
  }

  > div:nth-child(2) {
    width: 40%;
  }

  @media (max-width: 1599px) {
    flex-direction: column;

    > div:nth-child(1) {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }
}

.container2 {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 20px;
  margin-top: 20px;

  > div:nth-child(1) {
    width: 70%;
  }

  > div:nth-child(2) {
    width: 30%;
  }

  @media (max-width: 1599px) {
    flex-direction: column;

    > div:nth-child(1) {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }
}

.container3 {
  height: 100%;
  width: 100%;
  margin-top: 20px;
}

.inputContainer {
  margin-bottom: 20px;
  width: 300px;

  .error {
    color: $failedColor;
    font-size: small;
    margin: 0;
  }

  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
  }

  input {
    background-color: transparent;
    border: none;
    width: 100%;
    border-bottom: 2px solid $borderColor;
    font-size: 20px;
    padding: 10px 0;

    &:focus {
      outline: none;

      &::placeholder {
        color: transparent;
      }
    }

    &.invalid {
      border-bottom: 2px solid $pendingColor;
    }
  }
}

.calcResultsContainer {
  display: flex;
  gap: 20px;
  align-items: baseline;

  .list {
    border: 1px solid $primaryBackground;

    .row {
      display: flex;

      > div {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 0px 20px 6px 20px;
        width: 50%;
        word-break: break-all;
      }

      > div:nth-child(1) {
        color: $primaryColor;
        background-color: $primaryBackground;
      }

      &:not(:last-child) {
        > div:nth-child(1) {
          border-bottom: 1px solid $primaryColor;
        }

        > div:nth-child(2) {
          border-bottom: 1px solid $primaryBackground;
        }
      }
    }

    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 40%;
    }
  }
}

@media (max-width: 1440px) {
  .calcResultsContainer {
    flex-wrap: wrap;

    .list {
      &:nth-child(1),
      &:nth-child(2) {
        width: calc(50% - 10px);
      }

      &:nth-child(3) {
        width: calc(50% - 10px);
      }
    }
  }
}

@media (max-width: 992px) {
  .calcResultsContainer {
    flex-direction: column;

    .list {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 100%;
      }
    }
  }
}

.hr {
  border: 1px solid $borderColor;
  margin: 20px 0;
  height: 0;
}

.pageContainer {
  .headContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
      margin-top: 20px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;

    button {
      @include wisa-button($successColor);
      color: $primaryColor;
      padding: 0px 20px 6px 20px;
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}

.titleRightContainer {
  display: flex;
  align-items: center;
  gap: 30px;

  .statusContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    span {
      margin: 0;
      padding: 0;
      margin-top: -5px;
      margin-left: 5px;
    }

    .statusDot {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 6px;
      display: flex;

      &.Disconnected {
        box-shadow: 0 0 0 3px transparentize($failedColor, 0.8);
        background-color: $failedColor;
      }
      &.Connected {
        box-shadow: 0 0 0 3px transparentize($successRepColor, 0.8);
        background-color: $successRepColor;
      }
    }
  }
}
