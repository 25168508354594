@import "../../../assets/colors";

.checkbox {
  @include checkbox($successColor);

  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}
