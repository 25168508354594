@import "./colors";
@import "./utilities";
@import url("./fonts/Neue/stylesheet.css");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Neue Plak";
  background-color: $secondaryBackground;
  overflow: hidden;

  .errorAlert {
    background-color: #d9534f;
    color: $primaryColor;
    padding: 12px 20px 18px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
