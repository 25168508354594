@import "../../../assets/colors";

.select {
  background-color: $primaryColor;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: $secondaryColor;
  text-decoration: none;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $primaryColor 50%), linear-gradient(135deg, $primaryColor 50%, transparent 50%),
    linear-gradient(to right, $successColor, $successColor);
  background-position: calc(100% - 19px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.6em 2.6em;
  background-repeat: no-repeat;
  border-radius: 6px;
  width: 210px;

  &[multiple] {
    background-image: none;
  }

  &:focus {
    background-image: linear-gradient(45deg, $primaryColor 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, $primaryColor 50%), linear-gradient(to right, $successColor, $successColor);
    background-position: calc(100% - 16px) 1em, calc(100% - 20px) 1em, 100% 0;
    background-size: 5px 5px, 5px 5px, 2.6em 2.6em;
    background-repeat: no-repeat;
    outline: 0;
  }

  &[multiple]:focus {
    background-image: none;
  }
}
