@import "../../../../assets/colors";

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;

  .table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 5px 15px;
      text-align: center;
      font-size: 14px;
      border: 1px solid $borderColor;

      &:nth-child(1) {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      p {
        color: $primaryBackground;
        margin: 0;
        padding: 0;
        font-weight: bold;
      }
    }

    th {
      padding: 12px 15px;
      border: 1px solid $borderColor;
      text-align: center;
      font-size: 16px;
      color: $successColor;
      white-space: nowrap;

      span.info {
        margin-left: 5px;
        cursor: pointer;
      }

      &.tooltipHeader {
        cursor: pointer;
      }

      &:nth-child(1) {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.manageFirmwareWisaFirmwareButtonWrapper {
  display: flex;
  justify-content: center;

  button {
    @include wisa-button($successColor);
    color: $primaryColor;
    padding: 0px 20px 6px 20px;
    margin-top: 20px;
  }
}

.wisaFirmwareUploadFormContainer {
  width: 700px;
}

.wisaFirmwareUploadForm {
  &Fieldset {
    border: none;
    display: grid;
    grid-gap: 25px 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 40px 40px 40px 80px 40px;
  }

  &GridItem {
    grid-column: span 2;

    &:last-child {
      grid-column: 2 / span 2;
    }
  }

  &Field {
    background-color: $primaryColor;
    border: none;
    border-bottom: 2px solid $borderColor;
    text-align: left;
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    width: 324px;
  }

  &IconText {
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    margin-top: 0;
  }

  &IconLabel {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    grid-column: span 2;
  }

  &UploadIcon {
    fill: $successColor;
    height: 40px;
    margin-left: -10px;
  }

  &CheckboxWrapper {
    align-items: flex-start;
    display: flex;
    grid-column: 1 / span 2;
    margin-top: 22px;
  }

  &FileName {
    color: $successColor;
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    margin: 0;
  }

  label {
    font-style: normal;
    font-family: "Neue Plak";
    font-weight: normal;
    font-size: 13px;
    height: auto;
    margin-bottom: 0;
    margin-top: 0;

    span {
      top: 3px;
    }
  }
}

.error {
  color: $failedColor;
  font-size: small;
  margin: 0;
}
