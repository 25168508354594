@font-face {
  font-family: "Neue Plak";
  src: url("NeuePlak-ExtendedRegular.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak";
  src: url("NeuePlakText-Light.otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak";
  src: url("NeuePlak-ExtendedThin.otf");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak";
  src: url("NeuePlak-ExtendedLight.otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
